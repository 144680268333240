import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@optechai/design-system'
import {
  DatabaseZap,
  Binary,
  Calendar,
  Type,
  ToggleLeft,
  BracketsIcon,
  FileQuestionIcon,
  TagsIcon,
  FileIcon,
  LibrarySquareIcon,
  ImageIcon,
} from 'lucide-react'

import type { LiteralType } from '@server/services/workflow/tool/variable.schema'

export const TYPE_LABEL_LIST = [
  {
    label: 'Variable',
    value: 'Variable',
    description: 'A variable from the workflow or a tool',
  },
  {
    label: 'Number',
    value: 'NumberLiteral',
    description: 'A number value',
  },
  {
    label: 'Text',
    value: 'StringLiteral',
    description: 'An arbitrary line of text',
  },
  {
    label: 'Date',
    value: 'DateLiteral',
    description: 'Date and time value, in ISO 8601 format',
  },
  {
    label: 'True/False',
    value: 'BooleanLiteral',
    description: 'Boolean value',
  },
  {
    label: 'Unknown',
    value: 'Any',
    description: 'Type unspecified',
  },
  {
    description: 'A list of items',
    label: 'List',
    value: 'Array',
  },
  {
    description: 'A specific category or value from a list',
    label: 'Category',
    value: 'Union',
  },
  {
    description: 'A file to be processed',
    label: 'File',
    value: 'FileLiteral',
  },
  {
    description: 'A record or data object',
    label: 'Record',
    value: 'Object',
  },
  {
    description: 'A record or data object',
    label: 'Image',
    value: 'ImageLiteral',
  },
] satisfies Array<{
  description: string
  label: string
  value: LiteralType | 'Variable'
}>

const TYPE_LABEL_MAP = {
  Variable: 'Variable',
  NumberLiteral: 'Number',
  StringLiteral: 'Text',
  DateLiteral: 'Date',
  BooleanLiteral: 'True/False',
  Array: 'List',
  Any: 'Unknown',
  Union: 'Category',
  FileLiteral: 'File',
  Object: 'Record',
  ImageLiteral: 'Image',
} satisfies Record<LiteralType | 'Variable', string>

const TYPE_ICON_MAP = {
  NumberLiteral: Binary,
  StringLiteral: Type,
  DateLiteral: Calendar,
  BooleanLiteral: ToggleLeft,
  Array: BracketsIcon,
  Any: FileQuestionIcon,
  Union: TagsIcon,
  Variable: DatabaseZap,
  FileLiteral: FileIcon,
  Object: LibrarySquareIcon,
  ImageLiteral: ImageIcon,
} satisfies Record<LiteralType | 'Variable', React.ComponentType>

type LooseLiteralType = LiteralType | 'Variable' | 'boolean'
export interface TypeItemProps {
  type: LooseLiteralType
}

function defensiveSwitch(type: LooseLiteralType): LiteralType | 'Variable' {
  switch (type) {
    case 'boolean':
      return 'BooleanLiteral'
    default:
      return type
  }
}

/**
 * Primitive to represent a data type of an input or output.
 */
export const TypeIcon = ({ type }: TypeItemProps) => {
  const actualType = defensiveSwitch(type)
  const Icon = TYPE_ICON_MAP[actualType]
  return (
    <Tooltip>
      <TooltipTrigger asChild type="button">
        <Icon className="size-4 min-w-4 flex-shrink-0 text-primary" />
      </TooltipTrigger>
      <TooltipContent>
        <Text variant="p-s">
          {actualType !== 'Any'
            ? TYPE_LABEL_MAP[actualType]
            : 'Type unspecified'}
        </Text>
      </TooltipContent>
    </Tooltip>
  )
}

/**
 * Item for use in a list of data types.
 */
export const TypeItem = ({ type }: TypeItemProps) => {
  const actualType = defensiveSwitch(type)
  return (
    <div className="flex items-center gap-x-s overflow-hidden whitespace-nowrap rounded border p-s font-mono text-xs font-medium leading-none">
      <TypeIcon type={actualType} />
      <Text variant="p-s">{TYPE_LABEL_MAP[actualType]}</Text>
    </div>
  )
}
