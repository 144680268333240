import {
  type PillVariantTypes,
  getNumberDisplay,
} from '@optechai/design-system'
import { formatISO } from 'date-fns'

import { notEmpty, notEmptyString } from '@server/helpers/types'
export const getPercentageDisplay = (
  percentage: number | undefined,
  isSuccess?: (percentage: number) => boolean,
): { value: number | null; displayText: string; variant: PillVariantTypes } => {
  return {
    displayText: getNumberDisplay(percentage ?? null, '%'),
    value: percentage ?? null,
    variant:
      percentage === undefined
        ? 'grey'
        : isSuccess?.(percentage)
          ? 'success'
          : 'error',
  }
}

export const getUserDisplayName = (
  user:
    | {
        firstName?: string
        lastName?: string
        email?: string
        remoteId?: string
      }
    | null
    | undefined,
) => {
  const names = [user?.firstName, user?.lastName]
    .filter(notEmpty)
    .filter(notEmptyString)
  return names.length > 0
    ? names.join(' ')
    : notEmptyString(user?.email)
      ? user?.email
      : user?.remoteId
}

// Because the date is passed from a searchParam, catch any parsing errors.
export const formatDateFromUrl = (
  date: string | null,
  defaultDate: Date,
): string => {
  if (date !== null) {
    try {
      return formatISO(date)
    } catch (error) {}
  }
  return formatISO(defaultDate)
}
