import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip'
import { Text } from '../typography/text'

export type PillVariantTypes =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'primary-subtle'
  | 'error'
  | 'white'
  | 'grey'

const pillVariants = cva(
  'inline-flex px-s gap-1 items-center rounded-lg border transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1 flex-grow-0 w-max',
  {
    variants: {
      variant: {
        primary: 'border-transparent bg-primary-subtle text-primary',
        secondary: 'border-transparent bg-secondary-subtle text-secondary',
        success: 'border-transparent bg-secondary-subtle text-success',
        'primary-subtle': 'bg-surface text-link',
        error: 'border-transparent bg-error-subtle text-error',
        white: 'bg-surface text-subtle',
        grey: 'border-transparent bg-neutral text-subtle',
      },
      size: {
        small: 'h-6',
        medium: 'h-9',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'small',
    },
  },
)

export interface PillProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pillVariants> {
  icon?: React.ComponentType<{ size?: string | number }>
  tooltipText?: string
}

/**
 * __Pill__
 *
 * @template Built from shadcn/ui Badge component 'https://ui.shadcn.com/docs/components/badge'
 *
 * @see 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=9-1556&mode=design&t=rvsxhlawiMGrlKbR-0'
 *
 * @example
 * <Pill variant="primary" size="medium" icon={ExampleIcon} >
 *   Label
 * </Pill>
 */
function Pill({
  className,
  variant,
  size = 'small',
  icon: Icon,
  children,
  role,
  tooltipText,
  ...props
}: PillProps) {
  const mainContent = (
    <div
      className={cn(
        pillVariants({ variant, size }),
        role === 'button' && 'hover:opacity-80',
      )}
      role={role}
      tabIndex={role === 'button' ? 0 : undefined}
      {...props}
    >
      {Icon && <Icon size={size === 'medium' ? 16 : 12} />}
      {children && (
        <Text
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          variant={size === 'small' ? 'p-s' : 'p-m'}
        >
          {children}
        </Text>
      )}
    </div>
  )

  if (!tooltipText) {
    return mainContent
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild className={className}>
        {mainContent}
      </TooltipTrigger>
      {tooltipText && <TooltipContent>{tooltipText}</TooltipContent>}
    </Tooltip>
  )
}

export { Pill, pillVariants }
