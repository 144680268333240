import type { ReactNode } from 'react'

import { Code, cn } from '@optechai/design-system'
import { DatabaseZap } from 'lucide-react'

import type { SubscriberWorkflowTool } from '@server/models/tool/tool.dto'
import type { LiteralType } from '@server/services/workflow/tool/variable.schema'

import { TypeIcon } from '../primitive/type'

type Tool = SubscriberWorkflowTool & {
  requiredInputKeys?: string[]
}
type ToolProps = Partial<Tool> & { border?: boolean }

/**
 * Minimal deconstruction of a tool.
 *
 * @see
 */
export const ToolText = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex items-center gap-x-s overflow-hidden whitespace-nowrap rounded border bg-surface-card p-s font-mono text-xs font-medium leading-none">
      <DatabaseZap className="size-3 flex-shrink-0 text-secondary" />
      {children}
    </div>
  )
}

export interface ToolOutputProps {
  /**
   * Tool output name.
   */
  children: string
  /**
   * Type of output.
   */
  type?: LiteralType
  /**
   * Whether the output is a new or existing node.
   */
  variant?: 'node' | 'new'
}

/**
 * __ToolOutput__
 *
 * Used to display the output of a tool.
 */
export const ToolOutput = ({
  children,
  variant = 'node',
  type = 'Any',
}: ToolOutputProps) => {
  return (
    <div
      className={cn(
        'inline-flex items-center gap-x-xs rounded border border-border bg-surface p-s',
        variant === 'new' && 'border-primary',
      )}
    >
      <TypeIcon type={type} />
      <code className="font-mono text-xs font-medium leading-none text-primary">
        {children}
      </code>
    </div>
  )
}

/**
 * __ToolItem__
 *
 * @see
 */
export const Tool = (tool: ToolProps) => {
  if (!tool) {
    return null
  }

  return (
    <div
      className={cn(
        'flex flex-wrap items-center overflow-x-auto',
        tool.border && 'rounded border border-border px-s py-xs',
        (tool.outputMapping || tool.requiredInputKeys) && 'gap-s',
      )}
    >
      <ToolText>{tool.name}</ToolText>
      <ul className="flex flex-wrap items-center gap-s">
        {tool.requiredInputKeys?.map((key) => (
          <li key={key}>
            <Code>{key}</Code>
          </li>
        ))}
        {tool.outputMapping?.map((o) => (
          <li key={o.key}>
            <ToolOutput variant="new">{o.key}</ToolOutput>
          </li>
        ))}
      </ul>
    </div>
  )
}
