import type { WorkflowNodeV2Type } from './workflow-node-card'

type Copy = { label: string; description: string }

export const nodeTypeToCopyMap: { [key in WorkflowNodeV2Type]: Copy } = {
  Choice: {
    label: 'Choice',
    description:
      'Set conditional logic for workflows to create different scenarios',
  },
  Gather: {
    label: 'Gather',
    description:
      'Let AI take the reins. Enable AI to chat with a user and gather information to help form a decision.',
  },
  Analyze: {
    label: 'Analyze',
    description:
      'Analyze the data gathered by the AI to enrich or improve a workflow.',
  },
  New: { label: 'New', description: 'Create a new workflow node.' },
  Outcome: {
    label: 'Outcome',
    description:
      'Resolve a ticket process either by closing a ticket, re-assigning it or pass.',
  },
  Rag: {
    label: 'Reference Material',
    description: 'Answer a ticket using material from your help center.',
  },
  Call: {
    label: 'Call',
    description: 'Execute a subworkflow.',
  },
}

export const NODEV2_TYPE_OPTIONS = Object.entries(nodeTypeToCopyMap)
  .map(([value, copy]) => {
    return { value: value as WorkflowNodeV2Type, copy }
  })
  .filter((n) => n.value !== 'New') as {
  value: Exclude<WorkflowNodeV2Type, 'New'>
  copy: Copy
}[]

export const getLabelForNodeType = (type?: string) => {
  if (type === undefined) {
    return undefined
  }
  const nodeTypeOptionV2 = NODEV2_TYPE_OPTIONS.find((o) => o.value === type)
  return nodeTypeOptionV2 ? nodeTypeOptionV2.copy.label : type
}
