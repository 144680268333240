import { Pill, Text } from '@optechai/design-system'
import {
  Puzzle,
  Plus,
  Columns2,
  CheckCircle,
  Wand,
  Bolt,
  Book,
  Tag,
} from 'lucide-react'

import {
  getLabelForNodeType,
  type WorkflowNodeTypeAndNew,
} from '../workflow-node-card'

interface WorkflowNodeItemProps {
  /**
   * Label overrides the default label for the node type
   */
  label?: string
  /**
   * Should there be a small or medium icon?
   */
  size?: 'small' | 'medium'
  type: WorkflowNodeTypeAndNew
}

export const typeToIconMap = {
  // Everything above this line is deprecated
  Call: Puzzle,
  // This can't be used at the moment.
  Return: Puzzle,
  New: Plus,
  // Categorize: ListRestart,
  Tag: Tag,
  Choice: Columns2,
  Outcome: CheckCircle,
  Gather: Wand,
  Analyze: Bolt,
  Rag: Book,
} satisfies Record<
  WorkflowNodeTypeAndNew,
  React.ComponentType<{ size?: string | number }>
>

export const WorkflowNodeIcon = ({
  type,
  size = 16,
}: {
  type: WorkflowNodeTypeAndNew
  size?: number
}) => {
  const Icon = typeToIconMap[type]
  return Icon ? <Icon className="shrink-0 text-primary" size={size} /> : null
}

WorkflowNodeIcon.displayName = 'WorkflowNodeIcon'

export const WorkflowNodeItem = ({
  type,
  label,
  size = 'medium',
}: WorkflowNodeItemProps) => {
  const labelText =
    label || (type === 'New' ? 'Assign node type' : getLabelForNodeType(type))
  return (
    <div className="flex items-center gap-x-xs">
      <WorkflowNodeIcon type={type} />
      <Text color="subtle" variant={size === 'small' ? 'p-xs' : 'p-s'}>
        {labelText}
      </Text>
    </div>
  )
}

/**
 * A pill that displays the type of workflow node - used when not in menus
 */
export const WorkflowNodePill = ({
  type,
  label,
  size = 'small',
}: WorkflowNodeItemProps) => {
  const labelText =
    label || (type === 'New' ? 'Assign node type' : getLabelForNodeType(type))
  return (
    <Pill icon={typeToIconMap[type]} size={size} variant="primary-subtle">
      <Text color="subtle" variant={size === 'small' ? 'p-xs' : 'p-s'}>
        {labelText}
      </Text>
    </Pill>
  )
}
