import { z, type Primitive } from 'zod'

import type {
  PauseWorkflowInfo,
  WorkflowRunNode,
} from '../models/workflowRun/workflowRun.dto'

export const notEmpty = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  return value !== null && value !== undefined
}

export const notEmptyString = (value: string | null | undefined) => {
  return notEmpty(value) && value.trim() !== ''
}

// Because Typescript Partial doesn't handle nested objects
export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
        ? Subset<K[attr]> | null | undefined
        : K[attr]
}

/**
 * Use a DetailedError if we want to display error logs in the web-app.
 *
 * @param message The customer facing error message
 * @param details An object containing details about the error
 * */
export class DetailedError extends Error {
  public details: object | undefined

  constructor(message: string, details?: object) {
    super(message)
    this.name = this.constructor.name
    this.details = details
  }
}

export const isDetailedError = (error: unknown): error is DetailedError => {
  return error instanceof DetailedError
}

/**
 * Use this to indicate that the error is due to a user action
 * or a user action is needed to fix the error.
 * i.e This error is not actionable on our end, so we should not alert on it.
 */
export class UserActionError extends DetailedError {
  public recommendedAction: string | undefined
  constructor(message: string, details?: object, recommendedAction?: string) {
    super(message, details)
    this.recommendedAction = recommendedAction
  }
}

export const isUserActionError = (error: unknown): error is UserActionError => {
  return error instanceof UserActionError
}

export class RateLimitError extends DetailedError {
  public retryAfterMs: number | undefined

  constructor(message: string, retryAfterMs?: number) {
    super(message)
    this.name = this.constructor.name
    this.retryAfterMs = retryAfterMs
  }
}

export const isRateLimitError = (error: unknown): error is RateLimitError => {
  return error instanceof RateLimitError
}

// Distinct from RateLimitError because not all retriable errors are rate limit errors,
// but also... not all rate limit errors emitted from e.g. a Temporal activity mean that
// we should retry the activity (in the case of non-idempotent activitities).
export class RetriableActivityError extends DetailedError {
  public retryAfterMs: number | undefined

  constructor(message: string, retryAfterMs?: number) {
    super(message)
    this.name = this.constructor.name
    this.retryAfterMs = retryAfterMs
  }
}

export const isRetriableActivityError = (
  error: unknown,
): error is RetriableActivityError => {
  return error instanceof RetriableActivityError
}

export class PausedWorkflowEvent {
  node: WorkflowRunNode
  constructor({
    node,
    pauseInfo,
  }: {
    node: WorkflowRunNode
    pauseInfo?: PauseWorkflowInfo
  }) {
    // eslint-disable-next-line no-param-reassign
    node.nextNodeId = node.workflowNodeId
    this.node = node

    if (pauseInfo) {
      this.node.pauseInfo = pauseInfo
    }
  }
}

// Reference https://github.com/colinhacks/zod/issues/831
const isValidZodLiteralUnion = <T extends z.ZodLiteral<unknown>>(
  literals: T[],
): literals is [T, T, ...T[]] => {
  return literals.length >= 2
}

// Reference https://github.com/colinhacks/zod/issues/831
export const constructZodLiteralUnionType = <T extends Primitive>(
  constArray: readonly T[],
) => {
  const literalsArray = constArray.map((literal) => z.literal(literal))
  if (!isValidZodLiteralUnion(literalsArray)) {
    throw new Error(
      'Literals passed do not meet the criteria for constructing a union schema, the minimum length is 2',
    )
  }
  return z.union(literalsArray)
}
