import { Code } from '@optechai/design-system'
import * as prod from 'react/jsx-runtime'
import rehypeReact, { type Options } from 'rehype-react'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'

const opts = {
  Fragment: prod.Fragment,
  // @ts-expect-error - rehypeReact is not typed correctly
  jsx: prod.jsx,
  // @ts-expect-error - rehypeReact is not typed correctly
  jsxs: prod.jsxs,
  components: {
    code: ({ children }) => {
      return <Code>{children as string}</Code>
    },
  },
} satisfies Options
const processor = unified()
  .use(remarkParse)
  .use(remarkRehype)
  // @ts-expect-error - rehypeReact is not typed correctly
  .use(rehypeReact, opts)

export const markdownToReact = (markdown: string) => {
  return processor.processSync(markdown).result
}
