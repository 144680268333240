import { useLayoutEffect } from 'react'

import type { Customisations } from '@lorikeetai/widget/types'

export function generateCustomTheme(theme: Customisations['theme']) {
  if (!theme) {
    return ''
  }

  return `html.rebrand {
    ${theme.fontFamily ? '--lk-font-family-base: ' + theme.fontFamily + ';' : ''}
    ${theme.fontFamily ? '--lk-font-family-serif: ' + theme.fontFamily + ';' : ''}
    ${theme.brandColor ? '--lk-bg-primary: ' + theme.brandColor + ';' : ''}
    ${theme.textColor ? '--lk-text-default: ' + theme.textColor + ';' : ''}
    ${theme.brandColor ? '--lk-text-link: ' + theme.brandColor + ';' : ''}
    ${theme.brandColor ? '--lk-border-primary: ' + theme.brandColor + ';' : ''}
    ${theme.secondaryBrandColor ? '--lk-bg-secondary: ' + theme.secondaryBrandColor + ';' : ''}
    }`
}

const injectCSS = (css: string) => {
  const existing = document.getElementById('custom-styles')
  if (existing) {
    existing.innerText = css
    return
  }

  const $el = document.createElement('style')
  $el.setAttribute('id', 'custom-styles')
  $el.innerText = css
  document.body.appendChild($el)
}

export function useCustomTheme(customisations?: Customisations) {
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (customisations && customisations.theme) {
      const { theme } = customisations
      const custom = generateCustomTheme(theme)
      if (custom) {
        injectCSS(custom)
      }
    }
  }, [customisations])
}
