import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

import { Text } from '../typography/text'

export type TagVariantTypes =
  | 'green'
  | 'pink'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'neutral'

const tagVariants = cva(
  'px-xs py-xxs inline-flex gap-1 items-center rounded-sm border transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1 flex-grow-0',
  {
    variants: {
      variant: {
        neutral: 'border bg-surface-card text-subtle',
        green: 'border bg-accent-green-subtle text-success',
        yellow: 'border bg-accent-yellow-subtle text-subtle',
        pink: 'border bg-accent-pink-subtle text-subtle',
        blue: 'border bg-accent-blue-subtle text-primary',
        red: 'border bg-accent-red-subtle text-error',
      },
    },
  },
)

function hashStringToNumber(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32-bit integer
  }
  return hash
}

const VARIANTS_ARRAY = [
  'green',
  'yellow',
  'pink',
  'red',
  'blue',
  'neutral',
] satisfies Array<TagVariantTypes>

export interface TagProps extends VariantProps<typeof tagVariants> {
  children: React.ReactNode
}

/**
 * __Tag__
 *
 * @template Built from shadcn/ui Badge component 'https://ui.shadcn.com/docs/components/badge'
 *
 * @see 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=1780%3A1192&mode=design&t=819nm3zcJWT1ksY3-1'
 *
 * @example
 * <Tag variant="primary" >
 *   Label
 * </Tag>
 */
function Tag({ variant, children }: TagProps) {
  const appliedVariant =
    variant ||
    (typeof children === 'string'
      ? VARIANTS_ARRAY[hashStringToNumber(children) % VARIANTS_ARRAY.length]
      : 'neutral')
  return (
    <div className={cn(tagVariants({ variant: appliedVariant }))}>
      <Text
        className="overflow-hidden text-ellipsis whitespace-nowrap"
        variant="p-s"
      >
        {children}
      </Text>
    </div>
  )
}

export { Tag, tagVariants }
